import React from "react";

import { Row, Col, Form, FormGroup, Input, Button } from "reactstrap";

import Footer from "../components/Footer/Footer";

function LoginPage(props) {
  return (
    <div>
      <Row>
        <Col
          md="8"
          style={{
            backgroundImage: "url(" + require("assets/img/portal3.jpg") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "fixed",
            overflow: "hidden",
          }}
        >
          <div
            className="logo-container"
            style={{ marginBottom: "10px", width: "250px" }}
          >
            <img
              alt="comparelight logo"
              src={
                "http://medicaidradiology.com.ng/static/media/medicaidlogo.4a93c5ed.png"
              }
            ></img>
          </div>
          <div
            style={{
              minWidth: "100%",
              height: "Auto",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                marginTop: "50%",
                color: "green",
                fontWeight: "lighter",
              }}
            >
              PATIENT
            </h1>
            <h2
              style={{
                textAlign: "center",
                color: "green",
                fontWeight: "bold",
              }}
            >
              PORTAL
            </h2>
          </div>
        </Col>
        <Col
          md="2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "space-around",
            textAlign: "center",
            marginTop: "50px",
            marginLeft: "10%",
          }}
        >
          <Form
            style={{
              alignSelf: "center",
            }}
          >
            <Row>
              <FormGroup>
                <label>MR ID</label>
                <Input defaultValue="Mike" placeholder="Company" type="text" />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <label>Password</label>
                <Input
                  defaultValue="Andrew"
                  placeholder="Last Name"
                  type="text"
                />
              </FormGroup>
            </Row>
            <Row>
              <Button
                block
                className="btn-round"
                //type="submit"
                href="/admin/dashboard"
                // style={{
                //   color: colors.secondary,
                //   backgroundColor: colors.primary,
                // }}
                size="lg"
              >
                Login
              </Button>
            </Row>

            <Row
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <a className="link" href="/forgotPassword-page">
                Forgot your password?
              </a>
            </Row>
          </Form>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default LoginPage;
