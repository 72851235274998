import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.4.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.js";
import LoginPage from "views/LoginPage";
import ForgotPasswordPage from "views/ForgotPasswordPage";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/forgotPassword-page" component={ForgotPasswordPage} />
      <Redirect to="/login-page" />
      {/* <Redirect to="/admin/dashboard" /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);
