import React from "react";
import {
  Card,
  Col,
  CardBody,
  Row,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function Plans() {
  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Health Care Plans</h2>
            <p className="category">
              Latest care plans from Medicaid Radiology{" "}
              <a href="/" target="_blank" rel="noopener noreferrer">
                Check our website for more details
              </a>
              .
            </p>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col md="7">
            <Card>
              <img alt="..." src={require("assets/img/banner-plan3.jpg")} />
              <CardBody>
                <CardTitle tag="h4">Yearly Tests</CardTitle>
                <p>
                  Price: <span style={{ color: "green" }}>₦20,000/Year</span>
                </p>
                <CardText>
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </CardText>
                <Button color="default" type="submit" size="sm">
                  Subscribe
                </Button>
                <CardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <img alt="..." src={require("assets/img/plan-banner2.jpg")} />
              <CardBody>
                <CardTitle tag="h4">Monthly Tests</CardTitle>
                <p>
                  Price: <span style={{ color: "green" }}>₦1000/Month</span>
                </p>
                <CardText>
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </CardText>
                <Button color="default" type="submit" size="sm">
                  Subscribe
                </Button>
                <CardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Plans;
