import React, { Component } from "react";

// reactstrap components
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

class Upgrade extends Component {
  render() {
    return (
      <>
        <PanelHeader
          content={
            <div className="header text-center">
              <h2 className="title">Useful Links</h2>
              <p className="category">
                List of useful links by Medicaid Radiology{" "}
                <a href="/" target="_blank" rel="noopener noreferrer">
                  Check our website for more details
                </a>
                .
              </p>
            </div>
          }
        />
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>Medicaid Useful Links</CardTitle>
                  <p className="category">
                    Are you looking for more content or a page on the web?
                    Please check our list of recommended links.
                  </p>
                </CardHeader>
                <CardBody>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <blockquote className="blockquote blockquote-primary mb-0">
                      <p>NCDC Portal.</p>
                      <footer className="blockquote-footer">
                        Visit <cite title="Source Title">Link</cite>
                      </footer>
                    </blockquote>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <blockquote className="blockquote blockquote-primary mb-0">
                      <p>Emirate Portal.</p>
                      <footer className="blockquote-footer">
                        Visit <cite title="Source Title">Link</cite>
                      </footer>
                    </blockquote>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <blockquote className="blockquote blockquote-primary mb-0">
                      <p>Rapid Test Portal.</p>
                      <footer className="blockquote-footer">
                        Visit <cite title="Source Title">Link</cite>
                      </footer>
                    </blockquote>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <blockquote className="blockquote blockquote-primary mb-0">
                      <p>Medicaid Radiology.com.</p>
                      <footer className="blockquote-footer">
                        Visit <cite title="Source Title">Link</cite>
                      </footer>
                    </blockquote>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Upgrade;
