import React from "react";

import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Badge,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function Services() {
  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Medicaid Services</h2>
            <p className="category">
              List of services offered by Medicaid Radiology{" "}
              <a href="/" target="_blank" rel="noopener noreferrer">
                Check our website for more details
              </a>
              .
            </p>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Card>
            <CardHeader>
              <h5 className="card-category">Services Records</h5>
              <CardTitle tag="h4">Available Services</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Service</th>
                    <th>Center</th>
                    <th>Info</th>
                    <th>Price</th>
                    <th className="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Covid-19 Test</td>
                    <td>All</td>
                    <td>Read More</td>
                    <td style={{ color: "green" }}>₦1000</td>
                    <td className="text-right">
                      <Badge color="success">Book</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Eye Test</td>
                    <td>Abuja & Lagos</td>
                    <td>Read More</td>
                    <td style={{ color: "green" }}>₦5000</td>
                    <td className="text-right">
                      <Badge color="success">Book</Badge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default Services;
