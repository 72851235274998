import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
//import Typography from "views/Typography.js";
//import TableList from "views/TableList.js";
//import Maps from "views/Maps.js";
import Upgrade from "views/Upgrade.js";
import UserPage from "views/UserPage.js";
import Plans from "views/Plans";
import Services from "views/Services";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user-page",
    name: "User Profile",
    icon: "users_single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/plan-page",
    name: "Plans",
    icon: "health_ambulance",
    component: Plans,
    layout: "/admin",
  },
  {
    path: "/service-page",
    name: "Services",
    icon: "media-2_sound-wave",
    component: Services,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "ui-1_bell-53",
    component: Notifications,
    layout: "/admin",
  },

  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "location_map-big",
  //   component: Maps,
  //   layout: "/admin",
  // },

  // {
  //   path: "/extended-tables",
  //   name: "Table List",
  //   icon: "files_paper",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "design-2_ruler-pencil",
  //   component: Typography,
  //   layout: "/admin",
  // },
  {
    pro: true,
    path: "/medicaidradiology",
    name: "Medicaid Radiology",
    icon: "objects_spaceship",
    component: Upgrade,
    layout: "/admin",
  },
];
export default dashRoutes;
