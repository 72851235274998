import React from "react";
import { Form, FormGroup, Label, Input, Button, Container } from "reactstrap";

import Footer from "../components/Footer/Footer";

function ForgotPasswordPage() {
  return (
    <div>
      <Container>
        <h1
          className="title"
          style={{ marginTop: "100px", textAlign: "center" }}
        >
          Forgot Password ?
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "100px",
          }}
        >
          <Form className="form-inline">
            <FormGroup>
              <Label className="sr-only" htmlFor="staticEmail2">
                Email
              </Label>
              <input
                className="form-control-plaintext"
                defaultValue="Email Address"
                id="staticEmail2"
                readonly=""
                type="text"
              ></input>
            </FormGroup>
            <FormGroup className="mx-sm-3">
              <Label className="sr-only" htmlFor="inputPassword2">
                Enter your Email Address
              </Label>
              <Input
                id="inputPassword2"
                placeholder="Enter your Email Address"
                type="password"
              ></Input>
            </FormGroup>
            <Button color="default" type="submit">
              Confirm identity
            </Button>
          </Form>
        </div>
      </Container>

      <Footer />
    </div>
  );
}

export default ForgotPasswordPage;
