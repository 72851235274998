import React from "react";
// react plugin for creating notifications over the dashboard
// reactstrap components
import { Card, CardBody, Row, Badge } from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import CardFooter from "reactstrap/lib/CardFooter";

function Notifications() {
  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Notifications</h2>
            <p className="category">
              Latest news from Medicaid Radiology{" "}
              <a href="/" target="_blank" rel="noopener noreferrer">
                Check our News Blog for more
              </a>
              .
            </p>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Card>
            <CardBody>A new Health Plan is Out.</CardBody>
            <CardFooter>
              <Badge color="success">Read More</Badge>
            </CardFooter>
          </Card>
          <Card>
            <CardBody>How to avoid diabetes.</CardBody>
            <CardFooter>
              <Badge color="success">Read More</Badge>
            </CardFooter>
          </Card>
          <Card>
            <CardBody>A new Health Plan is Out.</CardBody>
            <CardFooter>
              <Badge color="success">Read More</Badge>
            </CardFooter>
          </Card>
          <Card>
            <CardBody>How to avoid diabetes.</CardBody>
            <CardFooter>
              <Badge color="success">Read More</Badge>
            </CardFooter>
          </Card>
          <Card>
            <CardBody>A new Health Plan is Out.</CardBody>
            <CardFooter>
              <Badge color="success">Read More</Badge>
            </CardFooter>
          </Card>
          <Card>
            <CardBody>How to avoid diabetes.</CardBody>
            <CardFooter>
              <Badge color="success">Read More</Badge>
            </CardFooter>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default Notifications;
